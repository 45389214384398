import { Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import { StyledMarquee } from "../Item/Item";
import Text from "../Typography/Text";
import {
    StyledArticleContainer,
    StyledImageWrapper,
    StyledMetabox,
    StyledNext,
    StyledNextPrev,
    StyledPrev,
} from "./ArticleStyles";

type CreditsProps = {
    title: string;
    data: {
        name?: string;
        role?: string;
    }[];
};

type MetaProps = {
    title: string;
    data: string[];
};

type NextPrevProps = {
    next?: {
        frontmatter: { title: string };
        slug: string;
    };
    previous?: {
        frontmatter: { title: string };
        slug: string;
    };
};

type ArticleProps = {
    Team: React.FunctionComponent<CreditsProps>;
    Meta: React.FunctionComponent<MetaProps>;
    Container: React.FunctionComponent;
    ImageWrapper: React.FunctionComponent;
    Metabox: React.FunctionComponent;
    NextPrev: React.FunctionComponent<NextPrevProps>;
};

const Article: ArticleProps = {
    Team: ({ title, data }) => {
        return (
            <div>
                <h5>{title}</h5>
                {data.map((item, index) => (
                    <div key={index}>
                        <span>
                            {item.name} – {item.role}
                        </span>
                    </div>
                ))}
            </div>
        );
    },
    Meta: ({ title, data }) => {
        return (
            <div>
                <h5>{title}</h5>
                {data.map((item, index) => (
                    <div key={index}>
                        <span>{item}</span>
                    </div>
                ))}
            </div>
        );
    },
    Container: ({ children }) => {
        return <StyledArticleContainer>{children}</StyledArticleContainer>;
    },
    ImageWrapper: ({ children }) => {
        return <StyledImageWrapper>{children}</StyledImageWrapper>;
    },
    Metabox: ({ children }) => <StyledMetabox>{children}</StyledMetabox>,
    NextPrev: ({ next, previous }) => (
        <StyledNextPrev>
            {next ? (
                <Link to={`/${next.slug}`}>
                    <StyledNext>
                        <h4>Next</h4>
                        <StyledMarquee>
                            <Text.Large interactive>
                                {next.frontmatter.title}
                            </Text.Large>
                        </StyledMarquee>
                    </StyledNext>
                </Link>
            ) : (
                <Link to={`/${previous.slug}`}>
                    <StyledPrev>
                        <h4>Previous</h4>
                        <StyledMarquee>
                            <Text.Large interactive>
                                {previous.frontmatter.title}
                            </Text.Large>
                        </StyledMarquee>
                    </StyledPrev>
                </Link>
            )}
        </StyledNextPrev>
    ),
};

export default Article;
