import React from "react";
const VideoBox = ({ src, title, ...props }) => (
    <figure className="video">
        <video autoPlay playsInline muted loop style={{ width: "100%" }}>
            <source src={src} type="video/mp4" />
        </video>
        <figcaption>{title}</figcaption>
    </figure>
);
export default VideoBox;
