import styled from "styled-components";

export const StyledArticleContainer = styled.article`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.75rem;
    font-size: 16px;

    p,
    h2,
    h3,
    h4,
    blockquote,
    pre,
    ul,
    ol,
    img {
        grid-column: span 2;
    }
    > img,
    figure,
    iframe,
    .video {
        grid-column: span 2;
    }

    img,
    picture,
    video {
        border-radius: 0.25rem;
        width: 100%;
    }

    video {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 1rem;

        p,
        h2,
        h3,
        h4,
        blockquote,
        pre,
        ul,
        ol {
            grid-column: 2 / span 3;
        }
        > img,
        > figure,
        iframe,
        .video {
            grid-column: 1 / span 5;
        }
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 2rem;
        padding-top: 6.25rem;
        padding-bottom: 4.25rem;

        p,
        h2,
        h3,
        h4,
        blockquote,
        pre,
        ul,
        ol {
            grid-column: 4 / span 6;
        }

        h2,
        h3 {
            margin-top: 0.75em;
            margin-bottom: 1.25em;
        }
        > img,
        > figure,
        iframe,
        .video {
            margin: 64px 0 72px;
            grid-column: 1 / span 12;
        }
    }
`;

export const StyledImageWrapper = styled.div`
    grid-column: span 2;
    @media screen and (min-width: 600px) {
        grid-column: 1 / span 5;
    }
    @media screen and (min-width: 1024px) {
        grid-column: 1 / span 12;
        margin: 64px 0 72px;
    }
`;

export const StyledMetabox = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0.75rem;
    grid-row-gap: 2rem;
    padding: 2rem 0;
    > div {
        grid-column: span 2;
        /* line-height: 1.75; */
    }
    aside {
        grid-column: span 2;
        font-size: 14px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0.75rem;
        grid-row-gap: 1.75rem;
    }
    @media screen and (min-width: 600px) {
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        > div {
            grid-column: span 4;
        }
        aside {
            grid-column: span 5;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 1rem;
        }
    }
    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        > div {
            grid-column: span 9;
        }
        aside {
            display: grid;
            grid-column: span 12;
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: 2rem;

            div:first-of-type {
                grid-column: span 3;
            }

            div {
                grid-column: span 2;
            }
        }
    }
`;

export const StyledNextPrev = styled.div`
    padding: 4rem 0;
    display: grid;
    row-gap: 2rem;
`;

export const StyledNext = styled.div`
    width: 100%;
    text-align: right;
    @media screen and (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
`;

export const StyledPrev = styled.div`
    width: 100%;
    @media screen and (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
`;
