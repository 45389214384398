import { graphql, Link, PageProps } from "gatsby";
import * as React from "react";
import Layout from "../components/Layout/Layout";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Seo from "../components/seo";
import Article from "../components/Article/Article";
import { BlogPostQueryQuery } from "../../graphql-types";
import VideoBox from "../components/Video/Video";
import Text from "../components/Typography/Text";
const { Team, Meta, Container, ImageWrapper } = Article;

export type PostProps = PageProps & {
    data: BlogPostQueryQuery;
    pageContext: {
        id: string;
        next?: {
            frontmatter: { title: string };
            slug: string;
        };
        previous?: {
            frontmatter: { title: string };
            slug: string;
        };
    };
};

const shortcodes = { Link, ImageWrapper, VideoBox };

const Post: React.FunctionComponent<PostProps> = ({
    data: { mdx },
    pageContext: { previous, next },
}) => (
    <Layout>
        <Seo title={mdx.frontmatter.title} />
        <div>
            <Text.Large>{mdx.frontmatter.title}</Text.Large>
            <Article.Metabox>
                <aside>
                    <Article.Team title="Team" data={mdx.frontmatter.credits} />
                    {/* <Article.Meta
                        title="Tools and Frameworks"
                        data={mdx.frontmatter.tools}
                    /> */}
                    {/* <Article.Meta title="Under" data={mdx.frontmatter.tags} /> */}
                    {mdx.frontmatter.agency && (
                        <Article.Meta
                            title="Agency"
                            data={[mdx.frontmatter.agency]}
                        />
                    )}
                    <Article.Meta title="Year" data={[mdx.frontmatter.year]} />
                </aside>
                <div>
                    <Text.Medium>{mdx.frontmatter.intro}</Text.Medium>
                </div>
            </Article.Metabox>

            <Article.Container>
                <MDXProvider components={shortcodes}>
                    <MDXRenderer frontmatter={mdx.frontmatter}>
                        {mdx.body}
                    </MDXRenderer>
                </MDXProvider>
            </Article.Container>
            <Article.NextPrev next={next} previous={previous} />
        </div>
    </Layout>
);

export const pageQuery = graphql`
    query BlogPostQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                tagline
                intro
                credits {
                    name
                    role
                }
                agency
                tags
                tools
                year
            }
        }
    }
`;

export default Post;
